<template>
  <div id="nav" class="d-flex flex-column" style="height:100vh">
  <router-view class="flex-fill" ></router-view>
  </div>
</template>

<script>

export default {
  name: "App",
  components: {
  },

  created() {
    this.test();
  },
  methods: {
    test() {
      console.log(window.localStorage);
    },
  },
};
</script>

<style>
@media (min-width: 1660px) {
  .container {
    max-width: 1560px;
  }
}
@media (max-width: 1024px) {
  .container {
    max-width: 800px;
  }
}
.word-overflow{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
