import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/About',
    name: 'About',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/ProductTextTemplate',
    name: 'ProductTextTemplate',
    component: () => import('../views/ProductTextTemplate.vue')
  },
  {
    path: '/EditTemplate',
    name: 'EditTemplate',
    component: () => import('../views/EditTemplate.vue')
  },
  {
    path: '/',
    redirect: { name: 'About' }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
